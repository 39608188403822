import React, { Component } from "react";

export default class WhyDsBanner extends Component {
  render() {
    return (
      <div className="header-title">
        <h1>Why Drupal</h1>
      </div>
    );
  }
}
