import React, { Component } from "react";

export default class BannerMinni extends Component {
  render() {
    return (
      <div className="header-title">
        <h1>Services</h1>
      </div>
    );
  }
}
